import React, { FC } from "react";
import classNames from "classnames";

import ArtifactStatusIcon from "@components/ArtifactStatusIcon";

import {
  PaymentItemProps,
  PaymentItemStyleType,
  PaymentItemVariantTypes,
  PaymentLineProps,
  PaymentLineStyleType,
  PaymentLineVariantTypes,
} from "../types";

export const PaymentLine: FC<PaymentLineProps> = ({
  variant = "default",
  size = "default",
  title,
  subtitle,
  value,
  tax,
  className,
}) => {
  const paymentLineStyles: PaymentLineStyleType = {
    default: {
      title: classNames("text-black"),
      subtitle: "text-grey-500",
    },
    failed: { title: "text-peach-250", subtitle: "text-peach-500" },
    light: { title: "text-grey-500", subtitle: "text-grey-500" },
    auto: {
      title: classNames("text-foreground"),
      subtitle: "text-foreground/50",
    },
    autoLight: { title: "text-foreground/50", subtitle: "text-foreground/50" },
  };

  const style = paymentLineStyles[variant] || {};
  const titleFontSize = size === "default" ? "text-sm" : "text-base";

  return (
    <div className={classNames("flex flex-1 items-center", className)}>
      <div className="flex-1 truncate">
        <p
          className={classNames(
            "font-medium truncate",
            style?.title,
            titleFontSize
          )}
        >
          {title}
        </p>
        {subtitle && (
          <p className={classNames("font-normal text-xs", style?.subtitle)}>
            {subtitle}
          </p>
        )}
      </div>
      <div className="text-right">
        <p className={classNames("font-medium", style?.title, titleFontSize)}>
          {value}
        </p>
        {tax && (
          <p className={classNames("font-medium text-xs", style?.subtitle)}>
            {tax}
          </p>
        )}
      </div>
    </div>
  );
};

const PaymentItem: FC<PaymentItemProps> = ({
  title,
  subtitle,
  value,
  tax,
  status,
  variant = "default",
}) => {
  const isVariantClientFlow = variant === "client-flow";
  const isStatusPaid = status === "paid";
  const isCancelled = ["cancelled", "void"].includes(status);
  const isStatusFailed = status === "failed";
  const isVariantClientFlowAndNotPaid =
    isVariantClientFlow && !isStatusPaid && !isCancelled;

  const styles: PaymentItemStyleType = {
    "client-flow": {
      iconWrap: classNames(isVariantClientFlowAndNotPaid && "!bg-transparent"),
      icon: classNames(isVariantClientFlowAndNotPaid && "!text-foreground/50"),
    },
  };

  const style = styles[variant] || {};

  const paymentItemVariants: Record<
    PaymentItemVariantTypes,
    PaymentLineVariantTypes
  > = {
    default: "default",
    "client-flow": "auto",
  };

  return (
    <div className="flex items-center" data-testid="payment-item">
      <ArtifactStatusIcon
        type="payment-item"
        status={isStatusFailed ? "failed" : isCancelled ? "cancelled" : status}
        variant="status-icon"
        size="sm"
        className="status-icon mr-2"
        iconWrapClassName={classNames(style?.iconWrap)}
        iconClassName={classNames(style.icon)}
      />
      <PaymentLine
        variant={isStatusFailed ? "failed" : paymentItemVariants[variant]}
        title={title}
        subtitle={subtitle}
        value={value}
        tax={tax}
      />
    </div>
  );
};

export default PaymentItem;
