import { FC } from "react";
import { OnTrackStatus } from "@practice/sdk";
import classNames from "classnames";
import { capitalize as cap, startCase, toLower } from "lodash";

import { statusInformation } from "./utils";

interface StatusLabelProps {
  status?: OnTrackStatus;
  inColor?: boolean;
  titleize?: boolean;
  capitalize?: boolean;
  className?: string;
}

const StatusLabel: FC<StatusLabelProps> = ({
  status,
  inColor = false,
  titleize = false,
  capitalize = false,
  className,
}) => {
  if (!status) return null;
  const colorStyles = statusInformation[status].labelColor;
  const statusText = statusInformation[status].statusTranslation;
  const text = titleize
    ? startCase(toLower(statusText))
    : capitalize
    ? cap(statusText)
    : statusText;
  return (
    <span className={classNames(inColor && colorStyles, className)}>
      {text}
    </span>
  );
};

export default StatusLabel;
