import { FC, useMemo } from "react";
import { PackageInstanceCard } from "@practice/sdk";
import classNames from "classnames";
import { isString } from "lodash";

import { PackageContentType } from "@lib/data/schemas/packages";
import { getCurrentCycle } from "@lib/models/package-instances/utils";
import pluralHelper from "@lib/utils/pluralHelper";

import { formatResetDate } from "@components/PackageInstance/utils";

type RollOverInfo = {
  total: number;
  type: PackageContentType;
};

interface PackageInstanceAcrossRemainingProps {
  className?: string;
  isComplete: boolean;
  value: number | string;
  position?: "top" | "bottom";
  resetDate?: Date | null;
  rollOverInfo?: RollOverInfo;
  isPaused?: boolean;
  isUsagePackage?: boolean;
  isSubscription?: boolean;
}

export const getRollOverInfo = (
  packageInstance: PackageInstanceCard
): RollOverInfo | undefined => {
  if (!packageInstance?.isRollover) return undefined;

  const currentCycle = getCurrentCycle(packageInstance);
  if (!currentCycle) return undefined;

  return {
    total: currentCycle.frequency,
    type: packageInstance?.contentType || "sessions",
  };
};

const formatRollOverInfo = (info: RollOverInfo) => {
  const isContentTypeTime = info.type === "time";

  if (isContentTypeTime) {
    return pluralHelper(info.total, "additional hour");
  }

  return pluralHelper(info.total, "new appointment");
};

const PackageInstanceAcrossRemaining: FC<
  PackageInstanceAcrossRemainingProps
> = ({
  isComplete,
  value,
  position = "bottom",
  resetDate,
  rollOverInfo,
  className,
  isPaused,
  isUsagePackage,
  isSubscription,
}) => {
  const formattedValue = () => {
    const shouldFormat = isString(value);
    if (!shouldFormat) return value;

    const [total, suffix] = value.split(" ");
    return `Book ${total} more ${suffix}`;
  };

  const additionalInfo = useMemo(() => {
    if (isSubscription) return undefined;
    if (isUsagePackage) return "Only pay for the time used";

    const info = [isComplete ? "Complete" : formattedValue()];
    if (isPaused) {
      info.push("No renewals until resumed");
    } else if (resetDate) {
      info.push(
        `${
          rollOverInfo ? formatRollOverInfo(rollOverInfo) : `Reset`
        } ${`on ${formatResetDate(resetDate)}`}`
      );
    }
    return info.join(" • ");
  }, [isComplete, isPaused, resetDate, rollOverInfo]);

  if (!additionalInfo) return null;
  return (
    <p
      className={classNames(
        "text-center font-medium text-xs py-1.5",
        position === "top" ? "rounded-t-lg" : "rounded-b-lg",
        isComplete && "bg-green-600 text-green-100",
        isPaused && "text-blue-500 bg-blue-950",
        !isComplete && !isPaused && "bg-blue-950 text-blue-100",
        className
      )}
    >
      {additionalInfo}
    </p>
  );
};

export default PackageInstanceAcrossRemaining;
