import { FC } from "react";
import {
  OnTrackStatus,
  PackageInstanceCard,
  PackageInstancePublic,
} from "@practice/sdk";
import classNames from "classnames";

import { momentDate } from "@lib/utils/todos";

import StatusDot from "@components/OnTrack/StatusDot";
import StatusLabel from "@components/OnTrack/StatusLabel";
import { statusInformation } from "@components/OnTrack/utils";
import { getPackageInstanceTrackingInfo } from "@components/PackageInstance/utils";

interface PackageTrackingStatusInfoProps {
  packageInstance: PackageInstanceCard | PackageInstancePublic;
  isPublicPage?: boolean;
  isInternal?: boolean;
  className?: string;
}

const PackageTrackingStatusInfo: FC<PackageTrackingStatusInfoProps> = ({
  packageInstance,
  isPublicPage = false,
  isInternal = false,
  className,
}) => {
  const trackingStatus = packageInstance?.onTrackStatus;
  const isOffTrack = trackingStatus === OnTrackStatus.OffTrack;
  const isAtRisk = trackingStatus === OnTrackStatus.AtRisk;
  const hasShownStatus = isOffTrack || isAtRisk;
  const allowClientView = packageInstance?.onTrackRules?.allowClientView;

  if (!hasShownStatus) return null;
  if (!isInternal && !allowClientView) return null;

  const { lastStatusChangeAt } =
    getPackageInstanceTrackingInfo(packageInstance);

  return (
    <div
      className={classNames(
        "rounded-full w-full items-center justify-center flex gap-1 px-0.5 text-sm font-medium",
        statusInformation[trackingStatus].bgColor,
        statusInformation[trackingStatus].labelColor,
        isPublicPage && "bg-background/90 text-foreground py-1 justify-start",
        className
      )}
    >
      <StatusDot
        status={trackingStatus}
        size={isPublicPage ? "small" : "medium"}
        withIcon
        swapIconColors={!isPublicPage}
      />
      <StatusLabel
        status={trackingStatus}
        capitalize
        className={classNames(isPublicPage ? "ml-1" : "-ml-0.5")}
      />
      {lastStatusChangeAt && (
        <>
          <span>since</span>
          <span>{momentDate(lastStatusChangeAt).format("MMM DD, YYYY")}</span>
        </>
      )}
    </div>
  );
};

export default PackageTrackingStatusInfo;
