import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const OffTrackIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0512 2.68146C12.5901 1.77285 11.4099 1.77285 10.9488 2.68146L2.15664 20.0077C1.70442 20.8989 2.28538 22 3.20779 22H20.7922C21.7146 22 22.2956 20.8989 21.8434 20.0077L13.0512 2.68146ZM10.794 9.29969C10.794 8.93053 11.0639 8.63126 11.397 8.63126H12.603C12.9361 8.63126 13.206 8.93053 13.206 9.29969V14.6472C13.206 15.0164 12.9361 15.3156 12.603 15.3156H11.397C11.0639 15.3156 10.794 15.0164 10.794 14.6472V9.29969ZM10.794 17.9894C10.794 17.251 11.3339 16.6525 12 16.6525C12.6661 16.6525 13.206 17.251 13.206 17.9894C13.206 18.7277 12.6661 19.3263 12 19.3263C11.3339 19.3263 10.794 18.7277 10.794 17.9894Z"
      />
    </svg>
  );
};

export default OffTrackIcon;
