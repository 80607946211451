import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const AtRiskIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.7398 2.24526C16.388 1.80335 17.2687 1.97495 17.7069 2.62854C19.4667 5.25305 20.5 8.4991 20.5 11.9993C20.5 15.4997 19.4671 18.7467 17.7069 21.3715C17.2686 22.0251 16.3879 22.1966 15.7397 21.7547C15.0916 21.3127 14.9215 20.4246 15.3598 19.7711C16.8019 17.6207 17.6667 14.9316 17.6667 11.9993C17.6667 9.06763 16.8016 6.37926 15.3597 4.22885C14.9215 3.57526 15.0917 2.68718 15.7398 2.24526Z" />
      <path d="M11.5702 6.47779C12.2474 6.08274 13.114 6.31611 13.5058 6.99904C14.3504 8.47134 14.8333 10.181 14.8333 12C14.8333 13.819 14.3504 15.5287 13.5058 17.001C13.114 17.6839 12.2474 17.9173 11.5702 17.5222C10.8929 17.1272 10.6615 16.2533 11.0532 15.5703C11.6551 14.5212 12 13.3034 12 12C12 10.6966 11.6551 9.47881 11.0532 8.42967C10.6615 7.74673 10.8929 6.87285 11.5702 6.47779Z" />
      <path d="M6.33333 14.8571C7.89814 14.8571 9.16667 13.5779 9.16667 12C9.16667 10.4221 7.89814 9.14289 6.33333 9.14289C4.76853 9.14289 3.5 10.4221 3.5 12C3.5 13.5779 4.76853 14.8571 6.33333 14.8571Z" />
    </svg>
  );
};

export default AtRiskIcon;
