import { ExtendedInvoiceType } from "@lib/data/schemas/invoice";
import { mapInvoiceStatus } from "@lib/invoices";

/**
 * Return the sum of a list of invoices
 * */
export const getTotalValues = (invoices: ExtendedInvoiceType[] = []) => {
  let paid = 0;
  let unpaid = 0;
  let total = 0;
  let cancelled = 0;

  invoices.forEach((item) => {
    const status = mapInvoiceStatus(item);
    total += item?.amount || 0;
    if (status === "paid") {
      paid += item?.amount || 0;
    } else if (["cancelled", "void"].includes(status)) {
      cancelled += item?.amount || 0;
    } else {
      unpaid += item?.amount || 0;
    }
  });

  return {
    paid: paid / 100,
    unpaid: unpaid / 100,
    total: total / 100,
    cancelled: cancelled / 100,
  };
};
