import { FC } from "react";
import {
  PackageInstanceCard,
  PackageInstanceCardCyclesInner,
  PackageInstancePublic,
  PackageInstancePublicCyclesInner,
} from "@practice/sdk";

import { getAllCycleDates } from "@lib/models/package-instances/utils";

import ArrowPaginationButtons, {
  ArrowPaginationButtonsVariants,
} from "@components/Buttons/ArrowPaginationButtons";

export interface PackageCyclePaginationProps {
  packageInstance: PackageInstanceCard | PackageInstancePublic;
  cycle: PackageInstanceCardCyclesInner | PackageInstancePublicCyclesInner;
  onChangeCycle: (
    cycle: PackageInstanceCardCyclesInner | PackageInstancePublicCyclesInner
  ) => void;
  variant?: ArrowPaginationButtonsVariants;
}

const PackageCyclePagination: FC<PackageCyclePaginationProps> = ({
  packageInstance,
  cycle,
  onChangeCycle,
  variant = "green",
}) => {
  const allCycles = getAllCycleDates(packageInstance);
  if (!cycle || !allCycles) return null;

  const isPaused = !!packageInstance?.pausedOn;
  const cycleIndex = allCycles.findIndex((item) => item === cycle);
  const disablePrevious = cycleIndex === 0;
  const disableNext = cycleIndex === allCycles.length - 1;

  if (disablePrevious && disableNext) return null;

  const nextCycle = allCycles?.[cycleIndex + 1];
  const prevCycle = allCycles?.[cycleIndex - 1];

  return (
    <ArrowPaginationButtons
      variant={isPaused ? "default" : variant}
      disablePrevious={disablePrevious}
      disableNext={disableNext}
      tooltipNext={nextCycle?.title}
      tooltipPrevious={prevCycle?.title}
      onClickPrevious={() => onChangeCycle(prevCycle)}
      onClickNext={() => onChangeCycle(nextCycle)}
    />
  );
};

export default PackageCyclePagination;
