import { FC, useState } from "react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import moment from "moment";

import { displayItemAmountString } from "@lib/products";

import ReconciliationSidebar from "@components/Reconciliation/ReconciliationSidebar";

interface UsagePackagePaymentDetailsProps {
  usagePackageCycleInvoices: any;
  enableDarkMode?: boolean;
  isPaused?: boolean;
  clientView: boolean;
}

const formatDate = (date: string) => moment(date).format("MMM D");

const UsagePackagePaymentDetails: FC<UsagePackagePaymentDetailsProps> = ({
  usagePackageCycleInvoices,
  enableDarkMode,
  isPaused,
  clientView,
}) => {
  const [selectedInvoice, setSelectedInvoice] = useState<any | null>(null);
  if (!usagePackageCycleInvoices) return null;

  const { method, invoice, autoChargeDate, cutOffDate } =
    usagePackageCycleInvoices;

  const isAutoPay = method === "automatic";

  const autoTitle = "Automatic payments";
  const manualTitle = "Invoice schedule";

  const invoiceAmount = displayItemAmountString(
    invoice?.totalInCents / 100,
    invoice?.currency
  );
  const currentDepositBalance = displayItemAmountString(
    invoice?.depositBalanceInCents / 100,
    invoice?.currency
  );

  const isDataEmpty = isEmpty(usagePackageCycleInvoices);

  const title = isAutoPay ? autoTitle : manualTitle;

  const cycleInfo = `Cycle closes ${formatDate(cutOffDate)}`;
  const getSubtitle = () => {
    if (isDataEmpty)
      return "Booking a session will generate the first invoice preview";
    if (isAutoPay)
      return `${cycleInfo}, auto-charge on ${formatDate(autoChargeDate)}`;
    return `${cycleInfo}, invoice sends on ${formatDate(autoChargeDate)}`;
  };

  const internalTitleClassNames = classNames(
    enableDarkMode && "dark:text-white",
    isPaused ? "text-foreground/80" : "text-green-200"
  );
  const publicTitleClassNames = "text-foreground";

  const internalSubtitleClassNames = classNames(
    enableDarkMode && "dark:text-grey-500",
    isPaused ? "text-foreground/50" : "text-green-500"
  );
  const publicSubtitleClassNames = "text-foreground/50";

  const internalInfoClassNames = classNames(
    enableDarkMode && "dark:text-white dark:bg-grey-500/20",
    isPaused
      ? "bg-foreground/5 text-foreground/80"
      : "bg-green-200/10 text-green-200 "
  );

  const publicInfoClassNames = "bg-foreground/5 text-foreground/80";

  const handleCloseReconciliationSidebar = () => setSelectedInvoice(null);

  const renderDepositBalance = () => {
    if (!currentDepositBalance) return null;
    return (
      <div className="flex items-center gap-4">
        <div className="w-full">
          <h5
            className={classNames(
              "text-xs",
              clientView ? publicSubtitleClassNames : internalSubtitleClassNames
            )}
          >
            Current deposit balance
          </h5>
        </div>
        <div
          className={classNames(
            "rounded-full flex items-center gap-0.5 px-2 py-1 text-sm font-medium",
            clientView ? publicInfoClassNames : internalInfoClassNames
          )}
        >
          {currentDepositBalance}
        </div>
      </div>
    );
  };

  const renderInvoiceDetails = () => {
    return (
      <div className="flex items-center gap-4">
        <div className="w-full">
          <h3
            className={classNames(
              "font-medium text-sm",
              clientView ? publicTitleClassNames : internalTitleClassNames
            )}
          >
            {title}
          </h3>
          <h5
            className={classNames(
              "text-xs",
              clientView ? publicSubtitleClassNames : internalSubtitleClassNames
            )}
          >
            {getSubtitle()}
          </h5>
        </div>
        {invoiceAmount && (
          <div
            className={classNames(
              "rounded-full flex items-center gap-0.5 px-2 py-1 text-sm font-medium cursor-pointer",
              clientView ? publicInfoClassNames : internalInfoClassNames
            )}
            onClick={() => setSelectedInvoice(invoice)}
          >
            {invoiceAmount}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {renderInvoiceDetails()}
      {renderDepositBalance()}
      {!!selectedInvoice && !clientView && (
        <ReconciliationSidebar
          show
          toggleShow={handleCloseReconciliationSidebar}
          onClickClose={handleCloseReconciliationSidebar}
          invoiceId={selectedInvoice.id}
          clientId={selectedInvoice.contactId}
        />
      )}
    </>
  );
};

export default UsagePackagePaymentDetails;
