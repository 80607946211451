import { FC } from "react";
import { PackageSchedulerType } from "api-services/definitions/package-instances";
import classNames from "classnames";
import Link from "next/link";

import { PackageContentType } from "@lib/data/schemas/packages";

import IconWithDetails from "@components/DisplayDetails/IconWithDetails";
import {
  getPackageSchedulerSuggestionInfo,
  getRemainingAppointments,
} from "@components/Package/utils";
import { Tooltip } from "@components/Tooltips/Tooltip";
import TypeIllustration from "@components/TypeIllustration";

interface PackageSchedulerItemProps {
  variant?: PackageContentType;
  totalRemainingInMinutes?: number;
  scheduler: PackageSchedulerType;
  enableDarkMode?: boolean;
  remainingClassnames?: string;
  showRemainingDetails?: boolean;
  link?: string;
  titleClassNames?: string;
  subtitleClassNames?: string;
  remainingAcross?: number;
  showSchedulerRemaining?: boolean;
  isUsagePackage?: boolean;
  allowFutureBooking?: boolean;
}

const PackageSchedulerItem: FC<PackageSchedulerItemProps> = ({
  variant = "sessions",
  totalRemainingInMinutes = 0,
  scheduler,
  enableDarkMode = false,
  showRemainingDetails = true,
  showSchedulerRemaining = true,
  remainingClassnames,
  link,
  titleClassNames,
  subtitleClassNames,
  remainingAcross,
  isUsagePackage = false,
  allowFutureBooking = false,
}) => {
  const {
    duration,
    title: schedTitle,
    icon: schedIcon,
    status,
  } = scheduler || {};
  const isVariantTime = variant === "time";
  const remaining = remainingAcross ?? getRemainingAppointments(scheduler);
  const completed = !isUsagePackage && !allowFutureBooking && remaining === 0;
  const schedulerDuration = duration || 0;
  const isSchedulerUnavailable =
    !isUsagePackage &&
    isVariantTime &&
    schedulerDuration > totalRemainingInMinutes;

  const shouldRenderRemaining =
    showSchedulerRemaining || (isVariantTime && isSchedulerUnavailable);

  const hasLinkAndNotCompleted = link && !completed;
  const shouldAddLink =
    (!isVariantTime && hasLinkAndNotCompleted) ||
    (isVariantTime && hasLinkAndNotCompleted && !isSchedulerUnavailable);

  const getBadgeCopy = () => {
    if (isSchedulerUnavailable) {
      return "Unavailable";
    }

    return completed
      ? "Completed"
      : `${remaining} ${showRemainingDetails ? "left" : ""}`;
  };

  const title = (
    <div className="flex justify-between w-full items-start text-base">
      <div
        className={classNames(
          enableDarkMode && "dark:text-white",
          "flex-shrink-1",
          titleClassNames
        )}
      >
        {schedTitle}
      </div>
    </div>
  );

  const renderRemaining = shouldRenderRemaining ? (
    <div
      className={classNames(
        "text-blue-100 bg-blue-950 rounded-lg px-2 py-[1px] text-sm flex-shrink-0 self-start",
        enableDarkMode && "dark:bg-blue-200 dark:text-white",
        remainingClassnames,
        completed && !isSchedulerUnavailable && "bg-green-600 text-green-100"
      )}
    >
      {getBadgeCopy()}
    </div>
  ) : undefined;

  const icon = <TypeIllustration slug={schedIcon} />;

  const defaultSubtitle = scheduler?.groupScheduler
    ? "Group scheduler"
    : `${duration} minutes`;

  const suggestedInfo = () => {
    const { suggestedSubtitle } = getPackageSchedulerSuggestionInfo(scheduler);
    if (scheduler.groupScheduler) return suggestedSubtitle;
    return `${suggestedSubtitle} (${duration} min)`;
  };

  const subtitle = (
    <div
      className={classNames(
        enableDarkMode && "dark:text-grey-500",
        subtitleClassNames
      )}
    >
      {isUsagePackage ? suggestedInfo() : defaultSubtitle}
    </div>
  );

  const content = (
    <IconWithDetails
      className={classNames(
        isSchedulerUnavailable && "opacity-50 cursor-not-allowed",
        completed && "cursor-not-allowed"
      )}
      title={title}
      titleClassNames={classNames(enableDarkMode && "dark:text-white")}
      icon={icon}
      subtitle={subtitle}
      subtitleClassNames={classNames(enableDarkMode && "dark:text-grey-500")}
      iconClassNames="bg-transparent -mx-2 flex-shrink-0"
      rightElement={renderRemaining}
      wrapperClassNames={classNames(!shouldRenderRemaining && "!pr-0")}
    />
  );

  const disabledInfo = ["inactive", "deleted"].includes(status)
    ? "Scheduler has been deactivated or deleted. Please contact your practitioner!"
    : "";

  if (disabledInfo) {
    return (
      <Tooltip trigger={<div className="opacity-50">{content}</div>}>
        {disabledInfo}
      </Tooltip>
    );
  }

  return shouldAddLink ? (
    <Link
      target="_blank"
      href={link}
      id={`package-scheduler-item-${scheduler.id}`}
    >
      {content}
    </Link>
  ) : (
    content
  );
};

export default PackageSchedulerItem;
