import { FC } from "react";
import classNames from "classnames";

import { useGetCurriculumProgress } from "@hooks/use-get-curriculum-progress";

import SubmitButton from "@components/Client/SubmitButton";
import LoadingSpinner from "@components/LoadingSpinner";
import TypeIllustration from "@components/TypeIllustration";

interface CurriculumSchedulerDetailsProps {
  schedulerId: string;
  packageInstanceId?: string;
  oid: string;
  onClick?: () => void;
  clientView?: boolean;
}

const CircularProgress = ({ percentage }: { percentage: number }) => {
  const degrees = (percentage / 100) * 360;

  return (
    <div className="flex items-center">
      <div className="relative w-6 h-6 bg-green-800 rounded-full">
        <div
          className="absolute inset-0 rounded-full"
          style={{
            background: `conic-gradient(#166534 0deg, #166534 ${degrees}deg, transparent ${degrees}deg)`,
          }}
        ></div>
        <div className="absolute inset-1 bg-green-900 rounded-full flex items-center justify-center"></div>
      </div>
      <div className="ml-2 text-xs">{Math.round(percentage)}%</div>
    </div>
  );
};

const CurriculumSchedulerDetails: FC<CurriculumSchedulerDetailsProps> = ({
  schedulerId,
  oid,
  onClick,
  packageInstanceId,
  clientView,
}) => {
  const { data } = useGetCurriculumProgress({
    groupSchedulerId: schedulerId,
    oid,
    packageInstanceId,
  });

  if (!data)
    return (
      <LoadingSpinner
        variant="transparent"
        height="20"
        width="20"
        className="mb-4"
      />
    );

  const percentage = (data.completedSessions * 100) / data.totalSessions;

  const showJoinButton = clientView && !data.attendance?.clientId;

  return (
    <div
      className={classNames(
        "flex items-center text-sm !mb-4 ",
        !showJoinButton &&
          onClick &&
          "cursor-pointer px-2 py-4 w-full hover:bg-foreground/5 border-foreground/10 border rounded-lg "
      )}
      onClick={!showJoinButton && onClick ? onClick : undefined}
    >
      <TypeIllustration slug={data.scheduler.icon} className="mr-4" />
      <div className="mr-auto">
        <div className="font-medium ">{data.scheduler.title}</div>
        {data.nextSession && (
          <div className="text-xs">
            Next:{" "}
            {data.nextSession?.startTime.toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </div>
        )}
      </div>
      {showJoinButton && onClick ? (
        <SubmitButton
          variant="ghost"
          size="small"
          onAction={onClick}
          actionTitle="Join"
        />
      ) : (
        <CircularProgress percentage={percentage} />
      )}
    </div>
  );
};

export default CurriculumSchedulerDetails;
