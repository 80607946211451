import { FC } from "react";
import { OnTrackStatus } from "@practice/sdk";
import classNames from "classnames";

import { statusInformation } from "./utils";

interface StatusDotProps {
  status?: OnTrackStatus;
  withIcon?: boolean;
  size?: "xxsmall" | "xsmall" | "small" | "medium" | "large";
  swapIconColors?: boolean;
  className?: string;
}

const dotSize = {
  xxsmall: "h-3 w-3",
  xsmall: "h-4 w-4",
  small: "h-5 w-5",
  medium: "h-6 w-6",
  large: "h-7 w-7",
};

const StatusDot: FC<StatusDotProps> = ({
  status,
  withIcon = false,
  size = "small",
  swapIconColors = false,
  className,
}) => {
  if (!status) return null;
  const Icon = statusInformation[status].statusIcon;
  const colorStyles = swapIconColors
    ? statusInformation[status].labelColor
    : statusInformation[status].iconColor;
  const sizeStyles = dotSize[size];

  return (
    <div
      className={classNames(colorStyles, sizeStyles, "rounded-full", className)}
    >
      {withIcon && Icon && <Icon className={classNames(sizeStyles, "p-1")} />}
    </div>
  );
};

export default StatusDot;
