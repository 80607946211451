import { FC } from "react";
import {
  PackageInstanceCard,
  PackageInstanceCardCyclesInner,
  PackageInstancePublic,
  PackageInstancePublicCyclesInner,
} from "@practice/sdk";
import classNames from "classnames";
import moment from "moment";

import { getLastCycle } from "@lib/models/package-instances/utils";
import { SVGIconProps } from "@lib/shared-types";
import { momentDate } from "@lib/utils/todos";

import CalendarIcon from "@components/Icons/CalendarIcon";
import CheckIcon from "@components/Icons/CheckIcon";
import FilledFlagIcon from "@components/Icons/FilledFlagIcon";
import PauseIcon from "@components/Icons/PauseIcon";
import ThunderIcon from "@components/Icons/ThunderIcon";

interface PackageInstanceDateTrackerProps {
  packageInstance: PackageInstanceCard | PackageInstancePublic;
  completedDate?: Date;
  enableDarkMode?: boolean;
  selectedCycle?:
    | PackageInstanceCardCyclesInner
    | PackageInstancePublicCyclesInner
    | null;
}

type StyleTypes = "default" | "paused" | "active" | "completed" | "ending";
type StyleDataType = {
  icon: FC<SVGIconProps>;
  iconWrapper?: string;
  iconColor?: string;
  pillWrapper?: string;
};
type StyleType = Record<StyleTypes, StyleDataType>;

const styles = (enableDarkMode: boolean = false): StyleType => ({
  default: {
    icon: CalendarIcon,
    iconWrapper: "bg-transparent",
    iconColor: enableDarkMode ? "text-foreground" : "text-green-200",
    pillWrapper: enableDarkMode
      ? "bg-foreground/7 text-foreground"
      : "text-green-200 bg-green-800",
  },
  paused: {
    icon: PauseIcon,
    pillWrapper: "bg-blue-950 text-blue-250",
    iconWrapper: "bg-blue-250",
  },
  active: {
    icon: ThunderIcon,
    iconWrapper: "bg-white",
    iconColor: "text-action-500",
    pillWrapper: "bg-action-500 text-white",
  },
  completed: {
    icon: CheckIcon,
    iconWrapper: "bg-transparent",
    iconColor: "text-green-200",
  },
  ending: {
    icon: FilledFlagIcon,
    iconWrapper: "bg-transparent",
    pillWrapper: "bg-green-300 text-white",
  },
});

const PackageInstanceDateTracker: FC<PackageInstanceDateTrackerProps> = ({
  packageInstance,
  completedDate,
  selectedCycle,
  enableDarkMode,
}) => {
  const { status } = packageInstance;
  const isCompleted = status === "completed";
  const isUsageBased = packageInstance?.packageType === "usage";

  const pausedOn = packageInstance?.pausedOn;
  const isPaused = !!pausedOn;
  const packageInstanceEndDate = packageInstance?.endDate;
  const momentPackageInstanceEndDate = momentDate(packageInstanceEndDate);

  const momentStart = momentDate(selectedCycle?.start).utc();
  const momentEnd = momentDate(selectedCycle?.end).utc();
  const startingInFuture = momentStart > moment();
  const isActive = ["active", "blocked"].includes(status) && !startingInFuture;
  const isPast = momentEnd < moment();
  const isCompletedDateInSelectedCycle =
    momentPackageInstanceEndDate >= momentStart &&
    momentPackageInstanceEndDate <= momentEnd;

  const usageStillActive = isUsageBased && !packageInstance.endDate;

  const isEnding =
    !isPaused &&
    !isCompleted &&
    !usageStillActive &&
    isCompletedDateInSelectedCycle &&
    packageInstanceEndDate &&
    moment() <= momentPackageInstanceEndDate;

  const getVariant = () => {
    if (isPaused) return "paused";
    if (isEnding) return "ending";
    if ((isCompleted || isPast) && !startingInFuture) return "completed";
    if (selectedCycle?.isCurrent) return "active";
    return "default";
  };

  const styleVariant = getVariant();
  const style = styles(enableDarkMode)[styleVariant];
  const { icon: Icon } = style;

  const formatUtcDate = (date?: Date, format?: string) =>
    date && moment(date).utc().format(format);

  const formattedEndDate = formatUtcDate(
    isUsageBased
      ? packageInstanceEndDate ?? getLastCycle(packageInstance)?.end
      : completedDate,
    "MMM DD YYYY"
  );

  const getInfo = () => {
    if (isEnding)
      return `Ending on ${formatUtcDate(packageInstanceEndDate, "MMM Do")}`;
    if (isPaused) return `Paused since ${formatUtcDate(pausedOn, "MMM Do")}`;
    if (isActive || startingInFuture) return selectedCycle?.title;
    return `Completed • ${formattedEndDate}`;
  };

  return (
    <div className="flex" data-variant={styleVariant}>
      <div
        className={classNames(
          "rounded-full p-1 pr-2 text-xs flex items-center gap-1",
          style?.pillWrapper ?? "border border-green-800"
        )}
      >
        <div
          className={classNames(
            "rounded-full p-0.5",
            style?.iconWrapper ?? "bg-green-200"
          )}
        >
          <Icon
            className={classNames(
              startingInFuture && "-scale-x-1",
              style?.iconColor ?? "text-white",
              "h-3.5 w-3.5"
            )}
          />
        </div>
        <div>{getInfo()}</div>
      </div>
    </div>
  );
};

export default PackageInstanceDateTracker;
