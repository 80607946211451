import { OnTrackStatus } from "@practice/sdk";

import usePersistentState from "@hooks/use-persistent-state";

import AtRiskIcon from "@components/Icons/AtRiskIcon";
import BlockIcon from "@components/Icons/BlockIcon";
import CheckIcon from "@components/Icons/CheckIcon";
import OffTrackIcon from "@components/Icons/OffTrackIcon";
import OnTrackIcon from "@components/Icons/OnTrackIcon";
import PauseIcon from "@components/Icons/PauseIcon";

export type PersistentValueType = {
  memberId?: string;
  query?: string;
  sortBy?: string;
  status?: OnTrackStatus;
  packageId?: string;
};

export const usePersistentOnTrackFilter = () => {
  const { persistentValue, persistentSetValue } =
    usePersistentState<PersistentValueType>("onTrackFilterOptions", {}, true);

  return {
    persistentOnTrack: persistentValue,
    setPersistentOnTrack: persistentSetValue,
  };
};

export const usePersistentOnTrackDashboardCards = () => {
  const { persistentValue, persistentSetValue } = usePersistentState<boolean>(
    "showOnTrackDashboardCards",
    false,
    true
  );

  return {
    hideOnTrackCards: persistentValue,
    setHideOnTrackCards: persistentSetValue,
  };
};

export const onTrackTableGridClasses = `
  gap-2 md:gap-4
  grid 
  grid-cols-[3fr_4fr_3fr]
  sm:grid-cols-[3fr_4fr_3fr]
  md:grid-cols-[3fr_4fr_2fr_2fr_2.5fr]
  lg:grid-cols-[3fr_4fr_3fr_3fr_3fr_3.5fr]
  xl:grid-cols-[3fr_4fr_2fr_2fr_3fr_2fr_2.5fr]
`;

export const statusInformation = {
  [OnTrackStatus.OnTrack]: {
    labelColor: "text-green-600",
    statusTranslation: "on track",
    statusIcon: OnTrackIcon,
    iconColor: "bg-green-600 text-white",
    bgColor: "bg-green-900",
  },
  [OnTrackStatus.AtRisk]: {
    labelColor: "text-action-300",
    statusTranslation: "at risk",
    statusIcon: AtRiskIcon,
    iconColor: "bg-action-700 text-white",
    bgColor: "bg-action-800/60",
  },
  [OnTrackStatus.OffTrack]: {
    labelColor: "text-peach-500",
    statusTranslation: "off track",
    statusIcon: OffTrackIcon,
    iconColor: "bg-peach-500 text-white",
    bgColor: "bg-peach-800/40",
  },
  [OnTrackStatus.Paused]: {
    labelColor: "text-blue-700",
    statusTranslation: "paused",
    statusIcon: PauseIcon,
    iconColor: "bg-blue-700 text-white",
    bgColor: "bg-blue-900",
  },
  [OnTrackStatus.Completed]: {
    labelColor: "text-green-500",
    statusTranslation: "completed",
    statusIcon: CheckIcon,
    iconColor: "bg-green-500 text-white",
    bgColor: "bg-green-800",
  },
  [OnTrackStatus.Disabled]: {
    labelColor: "text-grey-500",
    statusTranslation: "disabled",
    statusIcon: BlockIcon,
    iconColor: "bg-grey-500 text-white",
    bgColor: "bg-grey-900",
  },
};
