import { GroupSchedulerApi } from "@practice/sdk";
import useSWR from "swr";

import { useRequestIdGenerator } from "./use-request-id-generator";
import { useSDKApi } from "./use-sdk-api";

export const useGetCurriculumProgress = ({
  groupSchedulerId,
  oid,
  packageInstanceId,
}: {
  oid?: string;
  groupSchedulerId?: string;
  packageInstanceId?: string;
}) => {
  const api = useSDKApi(GroupSchedulerApi);

  const generateRequestId = useRequestIdGenerator("use-get-timeslot-attendees");

  return useSWR(
    `/organizations/${oid}/group-schedulers/${groupSchedulerId}/progress?packageInstanceId=${packageInstanceId}`,
    async () => {
      if (!oid || !groupSchedulerId) return;
      const res = await api.getCurriculumSchedulerProgress({
        groupSchedulerId,
        organizationId: oid,
        packageInstanceId,
        xRequestId: generateRequestId(),
      });
      return res;
    },
    {
      revalidateOnFocus: false,
    }
  );
};
