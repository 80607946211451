import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const OpenNewTabIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 7.5C17 7.22386 16.7761 7 16.5 7L11.5 7C11.2239 7 11 7.22386 11 7.5V8.5C11 8.77614 11.2239 9 11.5 9L13.5858 9L9.28249 13.3033C9.08722 13.4985 9.08722 13.8151 9.28249 14.0104L9.98959 14.7175C10.1849 14.9127 10.5014 14.9127 10.6967 14.7175L15 10.4142V12.5C15 12.7761 15.2239 13 15.5 13H16.5C16.7761 13 17 12.7761 17 12.5V7.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6ZM6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4Z"
      />
    </svg>
  );
};

export default OpenNewTabIcon;
