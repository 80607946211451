import { FC } from "react";
import { PackageInstanceCard, PackageInstancePublic } from "@practice/sdk";
import classNames from "classnames";

import { formattedPackageContentTypeValue } from "@lib/packages/package-instances";

import InfoIcon from "@components/Icons/InfoIcon";
import Progress from "@components/Progress/Progress";
import { Tooltip, TooltipSize } from "@components/Tooltips/Tooltip";

import PackageProgressDescription from "../PackageProgressDescription";

interface PackageProgressProps {
  className?: string;
  textClassName?: string;
  enableDarkMode?: boolean;
  packageInstance: PackageInstanceCard | PackageInstancePublic;
  title?: string;
}

const PackageProgress: FC<PackageProgressProps> = ({
  className,
  enableDarkMode = false,
  packageInstance,
  title = "Total package",
}) => {
  const totalAccrued = packageInstance?.totalAccrued || 0;
  const totalBooked = packageInstance?.totalBooked || 0;
  // the totalBooked counts everything including future cycles. In order to
  // show the correct progress, we need to limit the totalBooked to the
  // totalAccrued to show the correct progress when there is future bookings
  const totalBookedRelativeAccrued =
    totalBooked > totalAccrued ? totalAccrued : totalBooked;
  const totalCompleted = packageInstance?.totalCompleted || 0;
  const formattedTotalAvailable = formattedPackageContentTypeValue(
    packageInstance,
    totalAccrued,
    undefined,
    true
  );
  const isRecurringOrSubscription = ["recurring", "subscription"].includes(
    packageInstance?.packageType ?? ""
  );

  const isPaused = !!packageInstance?.pausedOn;
  const isTimeBased = packageInstance?.contentType === "time";

  const sharedDarkModeStyles = enableDarkMode && "dark:text-white";
  const styles = {
    paused: {
      base: classNames("text-grey-500", sharedDarkModeStyles),
      title: classNames("text-foreground/80", sharedDarkModeStyles),
    },
    default: {
      base: classNames(
        enableDarkMode ? "text-foreground/70" : "text-green-500",
        sharedDarkModeStyles
      ),
      title: classNames(
        enableDarkMode ? "text-foreground" : "text-green-200",
        sharedDarkModeStyles
      ),
    },
  };
  const style = isPaused ? styles.paused : styles.default;
  const tooltip = `${totalBookedRelativeAccrued} Booked & ${totalCompleted} Completed`;

  return (
    <div
      key={`package-instance-total-progress-${packageInstance.id}`}
      className={className}
    >
      {isRecurringOrSubscription ? (
        <div
          className={classNames(
            "text-sm font-medium flex items-center gap-1",
            style.title
          )}
        >
          {title}: {formattedTotalAvailable} accrued
          <Tooltip
            contentClassName="!p-3"
            trigger={<InfoIcon className="w-4 h-4" />}
            size={TooltipSize.small}
          >
            <p className="text-xs">
              Amount of sessions that have accumulated based on all previous
              cycles. Future cycles do not count as accrued.
            </p>
          </Tooltip>
        </div>
      ) : (
        <p className={classNames("text-sm font-medium", style.title)}>
          {title}
        </p>
      )}
      <Tooltip
        contentClassName="!p-2 !text-xs !rounded-lg"
        trigger={
          <Progress
            className="mb-1"
            secondaryCurrentStep={totalCompleted}
            currentStep={totalBookedRelativeAccrued}
            totalSteps={totalAccrued}
            enableDarkMode={enableDarkMode}
            isPaused={isPaused}
          />
        }
      >
        {tooltip}
      </Tooltip>
      <PackageProgressDescription
        formattedTotalAvailable={formattedTotalAvailable}
        enableDarkMode={enableDarkMode}
        isPaused={isPaused}
        isTimeBased={isTimeBased}
        totalBooked={totalBookedRelativeAccrued}
        totalCompleted={totalCompleted}
      />
    </div>
  );
};

export default PackageProgress;
