import React, { FC, FormEvent, ReactNode } from "react";
import classNames from "classnames";
import { isEmpty } from "lodash";

import Spinner from "@components/LoadingSpinner";

interface SubmitButtonProps {
  actionTitle: string | ReactNode;
  icon?: ReactNode;
  onAction: ((e: FormEvent) => Promise<void> | void) | (() => void);
  errors?: any; // @TODO: fix type
  isLoading?: boolean;
  dataHeapEventName?: string;
  size?: "small" | "regular" | "big" | "smaller";
  type?: "submit" | "button";
  variant?: "primary" | "secondary" | "ghost";
  className?: string;
  disabled?: boolean;
}

export const VARIANTS = {
  primary:
    "text-button-text disabled:text-button-text/20 bg-accent hover:bg-accent-90 active:bg-accent-80 disabled:bg-foreground/7",
  secondary:
    "text-button-text disabled:text-button-text/20 bg-accent/20 hover:bg-accent/40 active:bg-accent/60 disabled:bg-background/5",
  ghost:
    "text-foreground disabled:text-foreground/20 bg-foreground/7 hover:bg-foreground/20 active:bg-accent/40  disabled:bg-background/5",
  cancel: "bg-error/10 text-error hover:bg-error/20 active:bg-error/30",
};

const SubmitButton: FC<SubmitButtonProps> = ({
  actionTitle,
  icon,
  onAction,
  errors,
  isLoading,
  dataHeapEventName,
  size = "big",
  variant = "primary",
  type = "submit",
  className,
  disabled = false,
  ...rest
}) => {
  const sizeClasses = {
    smaller: "!px-2 !py-1",
    small: "!px-3 !py-2",
    regular: "justify-center  w-full",
    big: "w-full sm:w-auto text-lg",
  };

  const spinnerClasses = {
    smaller: "w-6 h-6",
  };

  return (
    <button
      data-heap-event-name={dataHeapEventName}
      onClick={isLoading ? undefined : onAction}
      disabled={isLoading || !isEmpty(errors) || disabled}
      type={type}
      className={classNames(
        "filter relative flex align-center justify-center font-medium px-8 leading-6 rounded-lg focus:outline-none disabled:cursor-not-allowed print:hidden",
        !isLoading && "py-3.5",
        isLoading && "py-1 sm:px-20",
        sizeClasses[size],
        VARIANTS[variant],
        className
      )}
      {...rest}
    >
      {isLoading ? (
        <Spinner className={spinnerClasses[size]} variant="transparent" />
      ) : icon ? (
        icon
      ) : (
        actionTitle
      )}
    </button>
  );
};

export default SubmitButton;
