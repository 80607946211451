import React, { FC, Fragment } from "react";
import classNames from "classnames";
import moment from "moment";

import { getNormalizedDate } from "@lib/appointments";
import { mapInvoiceStatus } from "@lib/invoices";
import { displayItemAmountString } from "@lib/products";
import { InvoiceStatusType } from "@lib/shared-types";

import PaymentItem, {
  PaymentLine,
} from "@components/PackageInstance/PaymentItem";

import {
  PaymentItemType,
  PaymentPlanProps,
  PaymentPlanStylesType,
} from "../types";

import { getTotalValues } from "./utils";

const PaymentPlan: FC<PaymentPlanProps> = ({
  packageInstance,
  invoices = [],
  className,
  variant = "default",
}) => {
  const isVariantDefault = variant === "default";
  const isVariantClientFlow = variant === "client-flow";
  const styles: PaymentPlanStylesType = {
    default: {
      border: "border-green-800",
      separator: "bg-grey-800",
    },
    "client-flow": {
      border: classNames("border-foreground/40"),
      separator: classNames("bg-foreground/20"),
    },
  };
  const style = styles[variant] || {};
  const packagePayments = packageInstance?.payments || [];
  const totalPayments = packagePayments.length;
  const isOneTimePayment = totalPayments === 1;
  const dataValues = getTotalValues(invoices);
  const isValidTotal = dataValues.total > 0;
  const isFullPaid = dataValues.total === dataValues.paid;
  const currency = invoices?.at(0)?.currency || "USD";

  const getPaymentSubtitle = (status: InvoiceStatusType, index: number) => {
    const isCancelled = ["cancelled", "void"].includes(status);
    const isFailed = status === "failed";
    const isShared = status === "shared";

    if (isCancelled) return "Payment cancelled";
    if (isFailed) return "Payment failed";
    if (isShared) return "Shared via email";
    if (index === 0) return "Charged at booking";
    return null;
  };

  const paymentItems: PaymentItemType[] = packagePayments.map(
    (item, index: number) => {
      const { invoiceId } = item;
      const invoice = invoices.find((invoice) => invoice.id === invoiceId);
      const status = invoice ? mapInvoiceStatus(invoice) : "draft";
      const amount = invoice?.amount ?? 0;

      const paidAt = invoice?.paidAt ? invoice.paidAt : null;
      const formatDate = (date: Date) => moment(date).format("MMMM Do YYYY");
      const isCancelled = ["cancelled", "void"].includes(status ?? "");
      const isFailed = status === "failed";
      const isCancelledOrFailed = isCancelled || isFailed;
      const hardCodedSubtitle = getPaymentSubtitle(status, index);
      const isPaid = status === "paid";

      return {
        id: invoiceId,
        title: isOneTimePayment
          ? isCancelledOrFailed
            ? "Cancelled"
            : isPaid
            ? "Paid in full"
            : item?.title ?? ""
          : item?.title ?? "",
        subtitle:
          hardCodedSubtitle ||
          (paidAt
            ? `Paid ${formatDate(getNormalizedDate(paidAt))}`
            : formatDate(getNormalizedDate(invoice?.dueDate))),

        value: displayItemAmountString(amount / 100, invoice?.currency) ?? "",
        tax: "Taxes included.",
        status,
        variant,
        show: false,
      };
    }
  );

  const paymentInfo = [
    !isFullPaid && {
      title: "Total package amount",
      value: displayItemAmountString(dataValues.total, currency),
      variant: isVariantDefault ? "light" : "autoLight",
    },
    !isFullPaid &&
      dataValues.paid > 0 && {
        title: "Amount paid",
        value: displayItemAmountString(dataValues.paid, currency),
        variant: isVariantDefault ? "light" : "autoLight",
      },
    !isFullPaid &&
      dataValues.cancelled > 0 && {
        title: "Amount cancelled",
        value: `-${displayItemAmountString(dataValues.cancelled, currency)}`,
        variant: isVariantDefault ? "light" : "autoLight",
      },
    !isFullPaid && {
      title:
        isOneTimePayment && paymentItems?.[0]?.status === "processing-ach"
          ? "Amount pending via ACH"
          : "Outstanding balance",
      value: displayItemAmountString(dataValues.unpaid, currency),
      size: "lg",
      tax: "Taxes included.",
      ...(isVariantClientFlow && { variant: "auto" }),
    },
    isFullPaid && {
      title: "Total paid",
      value: displayItemAmountString(dataValues.total, currency),
      size: "lg",
      tax: "Taxes included.",
      ...(isVariantClientFlow && { variant: "auto" }),
    },
  ].filter(Boolean);

  const renderTotalInfo = (
    <div className={classNames("border-t mt-3 pt-1", style?.border)}>
      {paymentInfo.map((item, index: number) => (
        <PaymentLine
          key={`payment-line-item-${index}`}
          className="py-2"
          {...item}
        />
      ))}
    </div>
  );

  const renderFullPaid = isValidTotal && (
    <PaymentItem
      title="Package fully paid"
      value={displayItemAmountString(dataValues.total, currency) as string}
      tax="Taxes included."
      status="paid"
    />
  );

  return (
    <div className={classNames("pt-4", className)}>
      {isFullPaid && !isOneTimePayment
        ? renderFullPaid
        : paymentItems.map(({ id, ...rest }, index: number) => (
            <Fragment key={`payment-item-${id}`}>
              <PaymentItem {...rest} />
              {paymentItems.length - 1 !== index && (
                <div
                  className={classNames(
                    "w-0.5 h-4 ml-2.5 my-1",
                    style?.separator
                  )}
                />
              )}
            </Fragment>
          ))}
      {renderTotalInfo}
    </div>
  );
};

export default PaymentPlan;
