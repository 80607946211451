import { PackageInstanceApi } from "@practice/sdk";
import useSWR from "swr";

import useLogger from "@hooks/use-logger";
import { useSDKApi } from "@hooks/use-sdk-api";

const useUsagePackageCycleInvoicesAPI = (props: {
  clientId: string;
  orgId: string;
  packageInstanceId: string;
}) => {
  const { orgId, packageInstanceId } = props;
  const { logger } = useLogger("use-usage-package-cycle-invoices");
  const packageInstanceApi = useSDKApi(PackageInstanceApi);

  const swrData = useSWR(
    `/organizations/${orgId}/package-instances/${packageInstanceId}/current-invoice`,
    async () => {
      if (!orgId || !packageInstanceId) return;
      try {
        return packageInstanceApi.getPackageInstanceCurrentInvoice({
          organizationId: orgId,
          packageInstanceId,
        });
      } catch (error: unknown) {
        logger.error({ error }, "Failed to fetch usage package cycle invoices");
        return;
      }
    },
    {
      dedupingInterval: 600000,
      onErrorRetry: (error) => {
        const errorStatus = error?.response?.status;

        // do not retry in case the error is 500 and message invoice not found
        // For some reason, the message is not been returned in the right way
        // from API, and we can't map the error message i the right way
        // @TODO: change it for 404 in the API, and update this code to handle it
        //        and prevent unnecessary retries
        if (errorStatus === 500) {
          return;
        }
      },
    }
  );

  return {
    data: swrData.data ?? [],
    isLoading: !swrData.data && !swrData.error,
    error: swrData.error,
    mutate: swrData.mutate,
  };
};

export default useUsagePackageCycleInvoicesAPI;
