import { FC } from "react";
import {
  PackageInstanceCard,
  PackageInstanceCardCyclesInner,
  PackageInstancePublic,
  PackageInstancePublicCyclesInner,
} from "@practice/sdk";
import classNames from "classnames";

import { isCycleBased } from "@lib/models/package-instances/utils";
import { formattedPackageContentTypeValue } from "@lib/packages/package-instances";

import InfoIcon from "@components/Icons/InfoIcon";
import { frequencyCopies } from "@components/Package/utils";
import Progress from "@components/Progress/Progress";
import { Tooltip, TooltipSize } from "@components/Tooltips/Tooltip";

import PackageProgressDescription from "../PackageProgressDescription";

export interface PackageCycleProgressProps {
  packageInstance: PackageInstanceCard | PackageInstancePublic;
  cycle: PackageInstanceCardCyclesInner | PackageInstancePublicCyclesInner;
  enableDarkMode?: boolean;
  title?: string;
}

interface ProgressCopyProps {
  enableDarkMode: boolean;
  title: string;
  isRecurringAndCurrentCycle?: boolean;
  className?: string;
}
const ProgressCopy: FC<ProgressCopyProps> = ({
  enableDarkMode,
  title,
  isRecurringAndCurrentCycle,
  className,
}) => (
  <div
    className={classNames(
      "text-sm font-medium flex gap-1 items-center",
      enableDarkMode ? "text-foreground/80" : "text-green-200",
      className
    )}
  >
    {title}
    {isRecurringAndCurrentCycle && (
      <Tooltip
        contentClassName="!p-3"
        trigger={<InfoIcon className="w-4 h-4" />}
        size={TooltipSize.small}
      >
        <p className="text-xs">
          Amount of sessions that can be booked in the current cycle
        </p>
      </Tooltip>
    )}
  </div>
);

const PackageCycleProgress: FC<PackageCycleProgressProps> = ({
  packageInstance,
  cycle,
  enableDarkMode = false,
  title,
}) => {
  if (!isCycleBased(packageInstance?.packageType)) {
    return null;
  }

  const isUsageBased = packageInstance?.packageType === "usage";
  const isPaused = !!packageInstance?.pausedOn;
  const isCurrentCycle = cycle?.isCurrent;
  const isRecurringOrSubscription = ["recurring", "subscription"].includes(
    packageInstance?.packageType ?? ""
  );
  const isRecurringAndCurrentCycle =
    isRecurringOrSubscription && isCurrentCycle;
  const isTimeBased = packageInstance?.contentType === "time";
  const totalBooked = cycle?.totalBooked ?? 0;
  const totalAvailableFromCycle = cycle?.totalAvailable ?? 0;
  const totalCompleted = cycle?.totalCompleted ?? 0;
  const frequencyString =
    frequencyCopies[packageInstance?.frequency ?? "months"] ?? "";
  const formattedTotalAvailable = formattedPackageContentTypeValue(
    packageInstance,
    totalAvailableFromCycle
  );
  const isPausedOrEnableDarkMode = isPaused || enableDarkMode;

  const renderBooked = () => {
    const formattedContentTypeAvailable = formattedPackageContentTypeValue(
      packageInstance,
      totalAvailableFromCycle - totalBooked,
      undefined,
      true
    );

    const formattedContentType = formattedPackageContentTypeValue(
      packageInstance,
      totalBooked,
      isUsageBased ? "appointment" : undefined
    );
    const usageTitle = `Booked ${formattedContentType}`;
    const recurrintTitlePrefix = isCurrentCycle
      ? `Current ${frequencyString.singular}`
      : cycle.title;
    const recurringAndCurrentCycleTitle = `${recurrintTitlePrefix}: ${formattedContentTypeAvailable} available to book`;
    const recurringTitle = isRecurringAndCurrentCycle
      ? recurringAndCurrentCycleTitle
      : recurrintTitlePrefix;
    const progressTitle = isUsageBased ? usageTitle : recurringTitle;
    return (
      <ProgressCopy
        className={isUsageBased ? "!text-lg" : undefined}
        title={title ?? progressTitle}
        enableDarkMode={isPausedOrEnableDarkMode}
        isRecurringAndCurrentCycle={isRecurringAndCurrentCycle}
      />
    );
  };

  const renderCompleted = () => {
    if (!isUsageBased) return null;
    const formattedContentType = formattedPackageContentTypeValue(
      packageInstance,
      totalCompleted,
      "appointment"
    );
    const title = `Completed ${formattedContentType}`;
    return (
      <ProgressCopy
        className="!text-lg"
        title={title}
        enableDarkMode={isPausedOrEnableDarkMode}
      />
    );
  };

  const renderProgress = () => {
    if (isUsageBased) return null;
    const tooltip = `${totalBooked} Booked & ${totalCompleted} Completed`;
    return (
      <Tooltip
        contentClassName="!p-2 !text-xs !rounded-lg"
        trigger={
          <Progress
            className="mb-1"
            currentStep={totalBooked}
            secondaryCurrentStep={totalCompleted}
            totalSteps={totalAvailableFromCycle}
            isPaused={isPaused}
          />
        }
      >
        {tooltip}
      </Tooltip>
    );
  };

  const renderRecurringData = () => {
    if (isUsageBased) return null;
    return (
      <>
        <PackageProgressDescription
          formattedTotalAvailable={formattedTotalAvailable}
          enableDarkMode={enableDarkMode}
          isPaused={isPaused}
          isTimeBased={isTimeBased}
          totalBooked={totalBooked}
          totalCompleted={totalCompleted}
        />
      </>
    );
  };

  return (
    <div>
      <div className={classNames(!isUsageBased && "space-y-4")}>
        {renderBooked()}
        {renderCompleted()}
      </div>
      {renderProgress()}
      {renderRecurringData()}
    </div>
  );
};

export default PackageCycleProgress;
