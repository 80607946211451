import { FC } from "react";
import classNames from "classnames";

interface ProgressDescriptionProps {
  children: string;
  isPaused?: boolean;
  enableDarkMode?: boolean;
}

const ProgressDescription: FC<ProgressDescriptionProps> = ({
  isPaused = false,
  enableDarkMode = false,
  children,
}) => (
  <p
    className={classNames(
      "text-xs",
      isPaused || enableDarkMode ? "text-foreground/40" : "text-green-500"
    )}
  >
    {children}
  </p>
);

interface PackageProgressDescriptionProps {
  isPaused: boolean;
  isTimeBased: boolean;
  enableDarkMode: boolean;
  totalBooked: number;
  totalCompleted: number;
  formattedTotalAvailable: string;
}

const PackageProgressDescription: FC<PackageProgressDescriptionProps> = ({
  isPaused,
  isTimeBased,
  enableDarkMode,
  totalBooked,
  totalCompleted,
  formattedTotalAvailable,
}) => {
  const isTimeBasedAndLessThanOneHour = (value: number) =>
    isTimeBased && value < 1.0;

  const getValueInMinutes = (value: number) => `${value * 60} minutes`;
  const totalBookedCopy = isTimeBasedAndLessThanOneHour(totalBooked)
    ? getValueInMinutes(totalBooked)
    : totalBooked;
  const totalCompletedCopy = isTimeBasedAndLessThanOneHour(totalCompleted)
    ? getValueInMinutes(totalCompleted)
    : totalCompleted;
  return (
    <div>
      <ProgressDescription
        isPaused={isPaused}
        enableDarkMode={enableDarkMode}
      >{`${totalBookedCopy} of ${formattedTotalAvailable} booked`}</ProgressDescription>
      <ProgressDescription
        isPaused={isPaused}
        enableDarkMode={enableDarkMode}
      >{`${totalCompletedCopy} of ${formattedTotalAvailable} completed`}</ProgressDescription>
    </div>
  );
};

export default PackageProgressDescription;
