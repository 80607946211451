import { FC, useMemo } from "react";
import classNames from "classnames";

export interface ProgressProps {
  className?: string;
  textClassName?: string;
  currentStep: number;
  secondaryCurrentStep?: number;
  totalSteps: number;
  enableDarkMode?: boolean;
  isPaused?: boolean;
}

const Progress: FC<ProgressProps> = ({
  className,
  currentStep,
  secondaryCurrentStep = 0,
  totalSteps,
  enableDarkMode,
  isPaused,
}) => {
  const enableDarkModeAndIsPaused = enableDarkMode && isPaused;
  const enableDarkModeAndNotPaused = enableDarkMode && !isPaused;
  const disableDarkModeAndIsPaused = !enableDarkMode && isPaused;
  const disableDarkModeAndNotPaused = !enableDarkMode && !isPaused;

  const progressBar = useMemo(
    () => (currentStep * 100) / totalSteps,
    [currentStep, totalSteps]
  );

  const secondaryProgressBar = useMemo(
    () => (secondaryCurrentStep * 100) / totalSteps,
    [secondaryCurrentStep, totalSteps]
  );

  const renderSecondaryProgress = secondaryCurrentStep ? (
    <div
      className={classNames(
        "rounded-full absolute h-2",
        enableDarkModeAndIsPaused && "dark:bg-foreground/20 bg-foreground/50",
        enableDarkModeAndNotPaused && "dark:bg-green-200 bg-green-200",
        disableDarkModeAndIsPaused && "bg-grey-500",
        disableDarkModeAndNotPaused && "bg-green-200"
      )}
      style={{ width: `${secondaryProgressBar}%` }}
    />
  ) : null;

  const renderProgress = (
    <div
      className={classNames(
        "flex rounded-full absolute h-2",
        enableDarkModeAndIsPaused && "dark:bg-foreground/20 bg-foreground/50",
        enableDarkModeAndNotPaused && "dark:bg-green-500 bg-green-500",
        disableDarkModeAndIsPaused && "bg-grey-800",
        disableDarkModeAndNotPaused && "bg-green-600"
      )}
      style={{ width: `${progressBar}%` }}
    />
  );

  return (
    <div
      className={classNames(
        "h-2 flex w-full rounded-full overflow-hidden relative",
        enableDarkModeAndIsPaused && "bg-foreground/7",
        enableDarkModeAndNotPaused && "bg-foreground/20",
        disableDarkModeAndIsPaused && "bg-grey-900",
        disableDarkModeAndNotPaused && "bg-white",
        className
      )}
    >
      {renderProgress}
      {renderSecondaryProgress}
    </div>
  );
};

export default Progress;
