import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const OnTrackIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.2191 11.3126C21.2905 11.5793 21.1322 11.8535 20.8655 11.925L19.8996 12.1838C19.6328 12.2553 19.3587 12.097 19.2872 11.8302L18.7006 9.64081L16.5342 13.3547C15.9398 14.3735 14.8491 15 13.6695 15C12.2421 15 10.9748 14.0866 10.5234 12.7324L9.55018 9.81285C9.38838 9.32742 8.9341 9 8.42242 9C7.95263 9 7.5269 9.27667 7.3361 9.70597L3.9138 17.4061L2.08618 16.5939L5.50848 8.89369C6.02028 7.74214 7.16225 7 8.42242 7C9.79496 7 11.0135 7.87828 11.4475 9.18039L12.4207 12.0999C12.5999 12.6374 13.1029 13 13.6695 13C14.1377 13 14.5707 12.7513 14.8066 12.3469L16.839 8.86276L15.0006 9.35536C14.7339 9.42683 14.4597 9.26854 14.3882 9.00181L14.1294 8.03588C14.0579 7.76915 14.2162 7.49498 14.483 7.42351L19.3126 6.12941C19.5793 6.05794 19.8535 6.21623 19.925 6.48297L21.2191 11.3126Z" />
    </svg>
  );
};

export default OnTrackIcon;
